import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_976e406a from 'nuxt_plugin_sentryserver_976e406a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_4e8f6e43 from 'nuxt_plugin_sentryclient_4e8f6e43' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_8c3aaace from 'nuxt_plugin_plugin_8c3aaace' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_0b8fc8af from 'nuxt_plugin_cookieuniversalnuxt_0b8fc8af' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtmq_0f3a724f from 'nuxt_plugin_nuxtmq_0f3a724f' // Source: ./nuxt-mq.js (mode: 'all')
import nuxt_plugin_pluginutils_6ff56614 from 'nuxt_plugin_pluginutils_6ff56614' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_03968342 from 'nuxt_plugin_pluginrouting_03968342' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_6548e170 from 'nuxt_plugin_pluginmain_6548e170' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_scrollbehaviorpolyfill_4f158591 from 'nuxt_plugin_scrollbehaviorpolyfill_4f158591' // Source: ./nuxt-polyfill/scroll-behavior-polyfill.js (mode: 'all')
import nuxt_plugin_intl_19de3c84 from 'nuxt_plugin_intl_19de3c84' // Source: ./nuxt-polyfill/intl.js (mode: 'all')
import nuxt_plugin_smoothscrollpolyfill_4bf58e9c from 'nuxt_plugin_smoothscrollpolyfill_4bf58e9c' // Source: ./nuxt-polyfill/smoothscroll-polyfill.js (mode: 'all')
import nuxt_plugin_intersectionobserver_64bf0f59 from 'nuxt_plugin_intersectionobserver_64bf0f59' // Source: ./nuxt-polyfill/intersection-observer.js (mode: 'all')
import nuxt_plugin_fontawesome_0f934909 from 'nuxt_plugin_fontawesome_0f934909' // Source: ./fontawesome.js (mode: 'all')
import nuxt_plugin_workbox_7565c2f3 from 'nuxt_plugin_workbox_7565c2f3' // Source: ../../shared/plugins/workbox.ts (mode: 'client')
import nuxt_plugin_dayjs_420d4a48 from 'nuxt_plugin_dayjs_420d4a48' // Source: ../../shared/plugins/dayjs.ts (mode: 'all')
import nuxt_plugin_i18n_6429dda2 from 'nuxt_plugin_i18n_6429dda2' // Source: ../../shared/plugins/i18n.ts (mode: 'all')
import nuxt_plugin_axiosInterceptors_33695d49 from 'nuxt_plugin_axiosInterceptors_33695d49' // Source: ../../shared/plugins/axiosInterceptors.ts (mode: 'all')
import nuxt_plugin_vuelidate_79e82608 from 'nuxt_plugin_vuelidate_79e82608' // Source: ../../shared/plugins/vuelidate.ts (mode: 'all')
import nuxt_plugin_directives_0db48f89 from 'nuxt_plugin_directives_0db48f89' // Source: ../../shared/plugins/directives.ts (mode: 'all')
import nuxt_plugin_formatters_877efaf8 from 'nuxt_plugin_formatters_877efaf8' // Source: ../../shared/plugins/formatters.ts (mode: 'all')
import nuxt_plugin_dragscroll_f1a21128 from 'nuxt_plugin_dragscroll_f1a21128' // Source: ../../shared/plugins/dragscroll.ts (mode: 'client')
import nuxt_plugin_dompurify_7e9f4c1c from 'nuxt_plugin_dompurify_7e9f4c1c' // Source: ../../shared/plugins/dompurify.ts (mode: 'client')
import nuxt_plugin_logger_dbcc5f06 from 'nuxt_plugin_logger_dbcc5f06' // Source: ../../shared/plugins/logger.ts (mode: 'all')
import nuxt_plugin_vTooltip_4b92f380 from 'nuxt_plugin_vTooltip_4b92f380' // Source: ../../shared/plugins/vTooltip.ts (mode: 'client')
import nuxt_plugin_initJsonapiOrm_162f769f from 'nuxt_plugin_initJsonapiOrm_162f769f' // Source: ../plugins/initJsonapiOrm.ts (mode: 'all')
import nuxt_plugin_multiPlatform_70db94a7 from 'nuxt_plugin_multiPlatform_70db94a7' // Source: ../plugins/multiPlatform.ts (mode: 'all')
import nuxt_plugin_axiosClients_ed081bca from 'nuxt_plugin_axiosClients_ed081bca' // Source: ../../shared/plugins/axiosClients.ts (mode: 'all')
import nuxt_plugin_initJsonapiAxios_57ff32da from 'nuxt_plugin_initJsonapiAxios_57ff32da' // Source: ../plugins/initJsonapiAxios.ts (mode: 'all')
import nuxt_plugin_initAuthService_304d7596 from 'nuxt_plugin_initAuthService_304d7596' // Source: ../plugins/initAuthService.ts (mode: 'all')
import nuxt_plugin_echo_52494fc8 from 'nuxt_plugin_echo_52494fc8' // Source: ../../shared/plugins/echo.ts (mode: 'client')
import nuxt_plugin_settingsServiceInit_1b0e401e from 'nuxt_plugin_settingsServiceInit_1b0e401e' // Source: ../plugins/settingsServiceInit.ts (mode: 'all')
import nuxt_plugin_initUxService_79cf3de0 from 'nuxt_plugin_initUxService_79cf3de0' // Source: ../plugins/initUxService.ts (mode: 'all')
import nuxt_plugin_initShopService_75eba964 from 'nuxt_plugin_initShopService_75eba964' // Source: ../plugins/initShopService.ts (mode: 'all')
import nuxt_plugin_microsoftTeams_4fe05082 from 'nuxt_plugin_microsoftTeams_4fe05082' // Source: ../plugins/microsoftTeams.ts (mode: 'client')
import nuxt_plugin_linkServiceInit_31be0d62 from 'nuxt_plugin_linkServiceInit_31be0d62' // Source: ../../shared/plugins/linkServiceInit.ts (mode: 'all')
import nuxt_plugin_posthog_159246ad from 'nuxt_plugin_posthog_159246ad' // Source: ../../shared/plugins/posthog.ts (mode: 'client')
import nuxt_plugin_libnuxtclientinitpluginclient61015430_79bcebc0 from 'nuxt_plugin_libnuxtclientinitpluginclient61015430_79bcebc0' // Source: ./lib.nuxt-client-init.plugin.client.61015430.js (mode: 'client')
import nuxt_plugin_meta_497657eb from 'nuxt_plugin_meta_497657eb' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"anny.bookings","titleTemplate":"%s | anny.bookings","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"msapplication-TileColor","content":"#333366"},{"name":"msapplication-TileImage","content":"_favicons\u002Fmstile-144x144.png"},{"name":"msapplication-config","content":"_favicons\u002Fbrowserconfig.xml"},{"name":"theme-color","content":"#131333"}],"link":[{"rel":"mask-icon","href":"_favicons\u002Fsafari-pinned-tab.svg","color":"#2b6af8"}],"noscript":[{"innerHTML":"\u003Cnoscript\u003EThis website requires JavaScript.\u003C\u002Fnoscript\u003E"}],"__dangerouslyDisableSanitizers":["noscript"],"script":[],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_976e406a === 'function') {
    await nuxt_plugin_sentryserver_976e406a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_4e8f6e43 === 'function') {
    await nuxt_plugin_sentryclient_4e8f6e43(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_8c3aaace === 'function') {
    await nuxt_plugin_plugin_8c3aaace(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_0b8fc8af === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_0b8fc8af(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmq_0f3a724f === 'function') {
    await nuxt_plugin_nuxtmq_0f3a724f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_6ff56614 === 'function') {
    await nuxt_plugin_pluginutils_6ff56614(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_03968342 === 'function') {
    await nuxt_plugin_pluginrouting_03968342(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_6548e170 === 'function') {
    await nuxt_plugin_pluginmain_6548e170(app.context, inject)
  }

  if (typeof nuxt_plugin_scrollbehaviorpolyfill_4f158591 === 'function') {
    await nuxt_plugin_scrollbehaviorpolyfill_4f158591(app.context, inject)
  }

  if (typeof nuxt_plugin_intl_19de3c84 === 'function') {
    await nuxt_plugin_intl_19de3c84(app.context, inject)
  }

  if (typeof nuxt_plugin_smoothscrollpolyfill_4bf58e9c === 'function') {
    await nuxt_plugin_smoothscrollpolyfill_4bf58e9c(app.context, inject)
  }

  if (typeof nuxt_plugin_intersectionobserver_64bf0f59 === 'function') {
    await nuxt_plugin_intersectionobserver_64bf0f59(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_0f934909 === 'function') {
    await nuxt_plugin_fontawesome_0f934909(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_7565c2f3 === 'function') {
    await nuxt_plugin_workbox_7565c2f3(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_420d4a48 === 'function') {
    await nuxt_plugin_dayjs_420d4a48(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6429dda2 === 'function') {
    await nuxt_plugin_i18n_6429dda2(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosInterceptors_33695d49 === 'function') {
    await nuxt_plugin_axiosInterceptors_33695d49(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_79e82608 === 'function') {
    await nuxt_plugin_vuelidate_79e82608(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_0db48f89 === 'function') {
    await nuxt_plugin_directives_0db48f89(app.context, inject)
  }

  if (typeof nuxt_plugin_formatters_877efaf8 === 'function') {
    await nuxt_plugin_formatters_877efaf8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dragscroll_f1a21128 === 'function') {
    await nuxt_plugin_dragscroll_f1a21128(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_dompurify_7e9f4c1c === 'function') {
    await nuxt_plugin_dompurify_7e9f4c1c(app.context, inject)
  }

  if (typeof nuxt_plugin_logger_dbcc5f06 === 'function') {
    await nuxt_plugin_logger_dbcc5f06(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vTooltip_4b92f380 === 'function') {
    await nuxt_plugin_vTooltip_4b92f380(app.context, inject)
  }

  if (typeof nuxt_plugin_initJsonapiOrm_162f769f === 'function') {
    await nuxt_plugin_initJsonapiOrm_162f769f(app.context, inject)
  }

  if (typeof nuxt_plugin_multiPlatform_70db94a7 === 'function') {
    await nuxt_plugin_multiPlatform_70db94a7(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosClients_ed081bca === 'function') {
    await nuxt_plugin_axiosClients_ed081bca(app.context, inject)
  }

  if (typeof nuxt_plugin_initJsonapiAxios_57ff32da === 'function') {
    await nuxt_plugin_initJsonapiAxios_57ff32da(app.context, inject)
  }

  if (typeof nuxt_plugin_initAuthService_304d7596 === 'function') {
    await nuxt_plugin_initAuthService_304d7596(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_echo_52494fc8 === 'function') {
    await nuxt_plugin_echo_52494fc8(app.context, inject)
  }

  if (typeof nuxt_plugin_settingsServiceInit_1b0e401e === 'function') {
    await nuxt_plugin_settingsServiceInit_1b0e401e(app.context, inject)
  }

  if (typeof nuxt_plugin_initUxService_79cf3de0 === 'function') {
    await nuxt_plugin_initUxService_79cf3de0(app.context, inject)
  }

  if (typeof nuxt_plugin_initShopService_75eba964 === 'function') {
    await nuxt_plugin_initShopService_75eba964(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_microsoftTeams_4fe05082 === 'function') {
    await nuxt_plugin_microsoftTeams_4fe05082(app.context, inject)
  }

  if (typeof nuxt_plugin_linkServiceInit_31be0d62 === 'function') {
    await nuxt_plugin_linkServiceInit_31be0d62(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_posthog_159246ad === 'function') {
    await nuxt_plugin_posthog_159246ad(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libnuxtclientinitpluginclient61015430_79bcebc0 === 'function') {
    await nuxt_plugin_libnuxtclientinitpluginclient61015430_79bcebc0(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_497657eb === 'function') {
    await nuxt_plugin_meta_497657eb(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
